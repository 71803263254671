import { last } from "lodash-es";

interface ConstraintViolation {
  field: string;
  message: string;
}

export function useHttpError() {
  const translation = useTranslation();
  function showToast(errorCode: number) {
    if (errorCode === 400) {
      useToast().error(
        translation.t("misc_generic_error_title"),
        translation.t("misc_400_error_text"),
      );
    } else if (errorCode === 404) {
      useToast().error(
        translation.t("misc_generic_error_title"),
        translation.t("misc_404_error_text"),
      );
    } else if (errorCode === 403) {
      useToast().error(
        translation.t("misc_generic_error_title"),
        translation.t("misc_403_error_text"),
      );
    } else {
      useToast().error(
        translation.t("misc_generic_error_title"),
        translation.t("misc_generic_error_text"),
      );
    }
  }

  function parseViolations(violations: ConstraintViolation[]) {
    return Object.assign(
      {},
      ...violations.map((v: ConstraintViolation) => {
        const fieldName = last(v.field.split(".")) as string;
        return { [fieldName]: v.message };
      }),
    );
  }

  return { showToast, parseViolations };
}
