import { useTranslation as translation } from "i18next-vue";

export function useTranslation() {
  return translation();
}

export const userLocale =
  navigator.languages && navigator.languages.length
    ? navigator.languages[0]
    : navigator.language;
