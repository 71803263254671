<script setup lang="ts">
import { useMultimediaStore } from "~/store/useMultimediaStore";
import { useAccountsStore } from "~/store/useAccountsStore";
import { useGeneralStore } from "~/store/useGeneralStore";
import { usePosthogEvent } from "~/composables/usePosthogEvent";

const multimediaStore = useMultimediaStore();
const accountsStore = useAccountsStore();
const generalStore = useGeneralStore();
const httpError = useHttpError();
const posthogEvent = usePosthogEvent();

const authors = computed(() => multimediaStore.authors);
const tags = computed(() => multimediaStore.tags);
const visualGroups = computed(() => multimediaStore.visualGroups);

async function apply() {
  await multimediaStore.filter(true);
  if (multimediaStore.fetchMultimediaRequest.error?.response) {
    httpError.showToast(
      multimediaStore.fetchMultimediaRequest.error.response.status,
    );
  } else {
    posthogEvent.multimediaFiltered();
    closeModal();
  }
}
function reset() {
  multimediaStore.restoreFilter();
  multimediaStore.filter();
  closeModal();
}

function closeModal() {
  generalStore.closeModal("MultimediaFilterModal");
}
</script>

<template>
  <CaModalWindow
    name="MultimediaFilterModal"
    modal-class="bottom-0 top-0 !mt-0"
  >
    <div class="header relative h-20">
      <div
        class="m-auto flex items-center justify-between px-6 pt-6 sm:max-w-5xl"
      >
        <div class="shrink-0 grow basis-0"></div>
        <div class="text-xl font-light">Filter content</div>
        <div class="flex shrink-0 grow basis-0 justify-end">
          <a
            class="flex cursor-pointer items-center"
            data-testid="multimediaFilterModal-button-close"
            @click="closeModal"
          >
            <div class="mr-2 hidden text-lg font-light sm:block">
              {{ $t("generic_button_close") }}
            </div>
            <CaIcon name="Close"></CaIcon>
          </a>
        </div>
      </div>
    </div>
    <div
      v-if="
        multimediaStore.isFetchingFilterData ||
        accountsStore.fetchAllRequest.isLoading
      "
      class="mt-20 w-full text-center"
    >
      <CaSpinner class="m-auto"></CaSpinner>
    </div>
    <div
      v-else
      class="m-auto mt-8 flex max-h-[72vh] flex-col items-center justify-between sm:max-w-md sm:px-4 lg:max-h-[75vh] lg:px-0"
    >
      <div class="mb-12 w-full grow overflow-y-auto">
        <FilterRow
          icon="Sort up"
          :title="$t('multimedia_filters_time_descending')"
          v-model="multimediaStore.filterState.sort.DESC"
          @update:model-value="
            multimediaStore.filterState.sort.ASC =
              !multimediaStore.filterState.sort.ASC
          "
        ></FilterRow>
        <FilterRow
          icon="Sort down"
          :title="$t('multimedia_filters_time_ascending')"
          v-model="multimediaStore.filterState.sort.ASC"
          @update:model-value="
            multimediaStore.filterState.sort.DESC =
              !multimediaStore.filterState.sort.DESC
          "
        ></FilterRow>
        <hr />
        <FilterRow
          icon="Paper plus"
          :title="$t('multimedia_filters_read')"
          v-model="multimediaStore.filterState.readStatus.READ"
        ></FilterRow>
        <FilterRow
          icon="Paper minus"
          :title="$t('multimedia_filters_unread')"
          v-model="multimediaStore.filterState.readStatus.UNREAD"
        ></FilterRow>
        <hr />
        <div class="mx-4 -mb-2 mt-2 font-light text-dark-gray">
          {{ $t("multimedia_filters_section_by_format") }}
        </div>
        <FilterRow
          icon="Memo"
          :title="$t('multimedia_filters_static')"
          v-model="multimediaStore.filterState.contentTypes.STATIC"
        ></FilterRow>
        <FilterRow
          icon="Video"
          :title="$t('multimedia_filters_rich')"
          v-model="multimediaStore.filterState.contentTypes.RICH"
        ></FilterRow>
        <hr />
        <div v-if="!$route.name?.toString().includes('modules')">
          <div class="mx-4 -mb-2 mt-2 font-light text-dark-gray">
            {{ $t("multimedia_filters_section_by_category") }}
          </div>
          <div>
            <FilterRow
              v-for="vg in visualGroups"
              :title="vg.name"
              v-model="multimediaStore.filterState.visualGroups[vg.id]"
            ></FilterRow>
          </div>
          <FilterRow
            v-if="!$route.name?.toString().includes('accounts')"
            v-for="tag in tags"
            :title="tag.name"
            v-model="multimediaStore.filterState.tags[tag.name]"
          ></FilterRow>
          <FilterRow
            :title="$t('multimedia_filters_general')"
            v-model="multimediaStore.filterState.general"
          ></FilterRow>
          <hr />
        </div>
        <div
          v-if="authors?.length"
          class="mx-4 -mb-2 mt-2 font-light text-dark-gray"
        >
          {{ $t("multimedia_filters_section_by_author") }}
        </div>
        <FilterRow
          v-for="author in authors"
          :title="author.name"
          v-model="multimediaStore.filterState.authors[author.id]"
        ></FilterRow>
      </div>
      <div class="w-full px-4">
        <CaButton
          block
          data-testid="multimediaFilterModal-button-apply"
          @click="apply"
          >{{ $t("generic_button_apply") }}</CaButton
        >
        <CaButton
          class="text-sm"
          block
          invert
          data-testid="multimediaFilterModal-button-reset"
          @click="reset"
          >Clear filters</CaButton
        >
      </div>
    </div>
  </CaModalWindow>
</template>

<style scoped lang="scss"></style>
