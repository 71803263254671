<script setup lang="ts">
import { Multimedia } from "~/types/multimedia/Multimedia";

interface Props {
  item?: Multimedia;
}
const props = withDefaults(defineProps<Props>(), {
  item: undefined,
});

const emit = defineEmits(["open"]);

const router = useRouter();
const route = useRoute();
const posthogEvent = usePosthogEvent();

function open() {
  emit("open");
  posthogEvent.openMultimedia(props.item, route.name?.toString() || "");
  router.push({ name: "multimedia-id", params: { id: props.item?.id } });
}
</script>

<template>
  <div
    v-if="item"
    class="flex min-h-24 cursor-pointer items-center justify-between gap-8 border-b-2 border-light-gray px-4 py-6 text-left first:pt-2 sm:px-6 lg:px-0"
    data-testclass="multimediaList-item"
    @click="open"
  >
    <img
      :src="item.thumbnailUrl"
      alt="Article Thumbnail"
      class="h-14 w-14 shrink-0 rounded object-cover"
      data-testclass="multimediaList-item-thumbnail"
    />
    <div class="flex min-w-0 grow flex-col items-start">
      <div
        class="mb-0.5 flex h-4 max-w-full flex-wrap items-center truncate text-xs font-light uppercase"
        data-testclass="multimediaList-item-labels"
      >
        <div v-for="(tag, idx) in item.labels" class="flex items-center">
          <div
            v-if="idx !== 0"
            class="mx-2 h-1 w-1 bg-dark-gray align-middle"
          ></div>
          <div class="whitespace-nowrap">{{ tag }}</div>
        </div>
      </div>
      <div
        class="mb-1 max-w-full leading-tight"
        data-testclass="multimediaList-item-title"
      >
        {{ item.title }}
      </div>
      <div
        class="my-1 max-w-full text-sm leading-tight"
        data-testclass="multimediaList-item-author"
      >
        {{ $t("multimedia_author_by") }}
        {{ item.multimediaAuthor.name }}
      </div>
      <div
        class="flex items-center gap-2 text-xs"
        data-testclass="multimediaList-item-duration"
      >
        <RuntimeSpan
          :only-min="item.contentType !== 'RICH'"
          format="short"
          :value="item.duration"
        ></RuntimeSpan>
        <span>{{ item.contentType === "RICH" ? " watch" : " read" }}</span>
        <div class="h-1 w-1 bg-medium-gray"></div>
        <DateSpan :value="item.publishedAt"></DateSpan>
      </div>
    </div>
    <div
      class="flex h-full shrink-0 flex-col items-center justify-center gap-8 self-center lg:flex-row-reverse lg:gap-4"
    >
      <div data-testclass="multimediaList-item-readStatusIcon">
        <div v-if="!item.read" class="h-2 w-2 bg-almost-black"></div>
      </div>
      <ContentTypeIcon
        :type="item.contentType"
        class="!h-4 !w-4"
        data-testclass="multimediaList-item-typeIcon"
      ></ContentTypeIcon>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
