<script setup lang="ts">
import { useGeneralStore } from "~/store/useGeneralStore";
import { Performance } from "~/types/account/Performance";
import { useNavbarHelpers } from "~/composables/useNavbarHelpers";
import { usePosthogEvent } from "~/composables/usePosthogEvent";

type PerformancePeriodListItem = {
  code: keyof Performance;
  title: string;
};

const posthogEvent = usePosthogEvent();

const { showTogglePerformance } = useNavbarHelpers();

const lastMonth = new Date();
lastMonth.setMonth(new Date().getMonth() - 1);

const performancePeriods: Record<keyof Performance, PerformancePeriodListItem> =
  {
    previousMonth: {
      code: "previousMonth",
      title: useTranslation().t("accounts_performance_period_one_month"),
    },
    discreteMonth: {
      code: "discreteMonth",
      title: lastMonth.toLocaleString("en-GB", { month: "long" }),
    },
    yearToDate: {
      code: "yearToDate",
      title: useTranslation().t("accounts_performance_period_year_to_date"),
    },
    sinceInception: {
      code: "sinceInception",
      title: useTranslation().t("accounts_performance_period_since_inception"),
    },
  };

const enabled = computed(
  () => useGeneralStore().showPerformance && showTogglePerformance.value,
);
let performancePeriod = computed(() => useGeneralStore().performancePeriod);
function setPerformancePeriod(newPeriodCode: keyof Performance) {
  let period = performancePeriods[newPeriodCode].title.toLowerCase();
  if (newPeriodCode === "discreteMonth") {
    period = "previous month";
  }
  posthogEvent.performancePeriodChanged(period);
  useGeneralStore().performancePeriod = newPeriodCode;
}
</script>

<template>
  <CaTransitionHeight :active="enabled">
    <div
      class="flex justify-center gap-x-1 overflow-auto border-b border-dark-gray border-opacity-20 text-sm font-light xs:gap-x-4"
      data-testclass="performancePeriodSelection"
    >
      <div
        v-for="period in performancePeriods"
        class="cursor-pointer whitespace-nowrap border-t-2 px-1 py-2 first:ml-6 last:mr-6 sm:py-3"
        :class="{
          ' border-almost-black font-regular':
            performancePeriod === period.code,
        }"
        data-testclass="performancePeriodSelection-period"
        @click="setPerformancePeriod(period.code)"
      >
        {{ period.title }}
      </div>
    </div>
  </CaTransitionHeight>
</template>

<style scoped lang="scss"></style>
