import posthog from "posthog-js";
import { Multimedia } from "~/types/multimedia/Multimedia";
import { MultimediaReactionType } from "~/types/multimedia/MultimediaReactionType";
import { User } from "~/types/user/User";

function makeMultimediaProperties(multimedia: Multimedia) {
  return {
    title: multimedia.title,
    type: multimedia.contentType,
    author: multimedia.multimediaAuthor?.name,
    general: multimedia.general,
  };
}

const reactionActionNames = {
  LIKE: "like",
  CONFUSED: "confused",
  TELL_ME_MORE: "more_info",
};

export function usePosthogEvent() {
  const route = useRoute();

  function reset() {
    posthog.reset();
  }
  function identify(user: User) {
    posthog.identify(user.id, {
      "app user ID": user.id,
      "app device ID": window.localStorage.getItem("deviceId"),
      "app user type": user.demo ? "demo" : "customer",
    });
  }

  function userAuthenticated(method: "sms" | "passkey") {
    posthog.capture("app_login", {
      action: method,
    });
  }

  function loggedOut() {
    posthog.capture("app_logout");
  }

  function authenticatorRegistered() {
    posthog.capture("authenticator_add");
  }

  function authenticatorRemoved() {
    posthog.capture("authenticator_remove");
  }

  function dashboardHelpClicked() {
    posthog.capture("dashboard_help_click");
  }

  function multimediaSearched() {
    posthog.capture("multimedia_search", {
      screen: route.meta.screenName,
    });
  }
  function multimediaFiltered() {
    posthog.capture("multimedia_filter", {
      screen: route.meta.screenName,
    });
  }

  function openMultimedia(item: Multimedia | undefined, route: string) {
    if (!item) return;
    posthog.capture("multimedia_open", {
      ...makeMultimediaProperties(item),
      modules: item?.modules.map((m) => m.name),
      tags: item?.tags,
      asset_classes: item?.visualGroups.map((g) => g.name),
    });
  }

  function videoInteraction(
    item: Multimedia | undefined,
    action: "play" | "pause" | "forward" | "backward" | "close",
  ) {
    if (!item) return;
    posthog.capture("multimedia_video_interact", {
      ...makeMultimediaProperties(item),
      action,
      modules: item?.modules.map((m) => m.name),
      tags: item?.tags,
      asset_classes: item?.visualGroups.map((g) => g.name),
    });
  }

  function react(item: Multimedia, reaction: MultimediaReactionType) {
    if (!item) return;
    posthog.capture("multimedia_react", {
      action: reactionActionNames[reaction],
      ...makeMultimediaProperties(item),
    });
  }

  function requestMoreInfo(item: Multimedia) {
    if (!item) return;
    posthog.capture("multimedia_inquiry", makeMultimediaProperties(item));
  }

  function requestBalanceSheets() {
    posthog.capture("balance_sheets_inquiry");
  }

  function referAFriend() {
    posthog.capture("app_referral");
  }

  function becomeAClient() {
    posthog.capture("app_inquiry");
  }

  function requestDeleteAccount() {
    posthog.capture("deletion_inquiry");
  }

  function performanceEnabled(enabled: boolean) {
    posthog.capture("performance_toggle", {
      action: enabled ? "on" : "off",
      screen: route.meta.screenName,
    });
  }

  function performancePeriodChanged(period: string) {
    posthog.capture("performance_period_change", {
      period,
      screen: route.meta.screenName,
    });
  }

  function documentOpened(title: string) {
    posthog.capture("document_open", {
      title,
    });
  }

  function notificationClicked(item: Multimedia) {
    posthog.capture("notification_click", makeMultimediaProperties(item));
  }

  return {
    reset,
    identify,
    userAuthenticated,
    loggedOut,
    authenticatorRegistered,
    authenticatorRemoved,
    dashboardHelpClicked,
    multimediaFiltered,
    multimediaSearched,
    openMultimedia,
    videoInteraction,
    react,
    requestMoreInfo,
    requestBalanceSheets,
    referAFriend,
    becomeAClient,
    requestDeleteAccount,
    performanceEnabled,
    performancePeriodChanged,
    documentOpened,
    notificationClicked,
  };
}
