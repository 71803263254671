const environment = import.meta.env.VITE_ENV;

export default async function loadFirebaseConfig() {
  if (environment === "local") {
    return await import(`~/../public/config/firebase-config.local.json`);
  } else if (environment === "staging") {
    return await import(`~/../public/config/firebase-config.staging.json`);
  } else if (environment === "production") {
    return await import(`~/../public/config/firebase-config.production.json`);
  }
  return {};
}
