<script setup lang="ts">
import { useMultimediaStore } from "~/store/useMultimediaStore";
import NoMultimediaImage from "~/assets/no-multimedia.svg";

const multimedia = computed(() => {
  return useMultimediaStore().multimedia;
});

const filterActive = computed(() => useMultimediaStore().filterActive);
const noMorePages = computed(() => useMultimediaStore().noMorePages);
const onFirstPage = computed(() => useMultimediaStore().onFirstPage);

const user = computed(() => useAuthStore().user);

async function fetchMore() {
  await useMultimediaStore().filter(false, true);
}
</script>

<template>
  <div class="bg-lighter-gray lg:bg-transparent">
    <div
      class="flex items-center justify-between gap-4 px-4 py-4 sm:px-6 lg:px-0 lg:pb-6"
    >
      <CaIcon name="News"></CaIcon>
      <div class="grow text-lg">
        <span v-if="user?.forename">
          {{ $t("multimedia_title_personalized", { name: user.forename }) }}
        </span>
        <span v-else>{{ $t("multimedia_title") }}</span>
      </div>
      <CaIcon
        v-if="!filterActive"
        name="Filter"
        class="cursor-pointer"
        :active="false"
        data-testid="multimediaList-button-filter"
        @click="useGeneralStore().openModal('MultimediaFilterModal')"
      ></CaIcon>
      <CaIcon
        v-else
        name="Filter"
        class="cursor-pointer"
        :active="true"
        data-testid="multimediaList-button-filter"
        @click="useGeneralStore().openModal('MultimediaFilterModal')"
      ></CaIcon>
      <CaIcon
        name="Search"
        class="cursor-pointer"
        data-testid="multimediaList-button-search"
        @click="$router.push('/multimedia/search')"
      ></CaIcon>
    </div>

    <div
      v-if="
        useMultimediaStore().fetchMultimediaRequest.isLoading && onFirstPage
      "
      class="mt-20 w-full pb-10 text-center"
    >
      <CaSpinner class="m-auto"></CaSpinner>
    </div>
    <div
      v-else-if="useMultimediaStore().fetchMultimediaRequest.error"
      class="mt-8 pb-10 text-center"
    >
      <HttpError
        :error="useMultimediaStore().fetchMultimediaRequest.error"
      ></HttpError>
    </div>
    <div v-else-if="!multimedia.length" class="mt-8 pb-10 text-center">
      <NoMultimediaImage alt="No multimedia" class="m-auto" />
      <div class="mt-8 font-light text-dark-gray">
        <p>
          {{ $t("error_state_multimedia_list_title") }}
        </p>
      </div>
    </div>
    <div v-else>
      <MultimediaListItem
        v-for="row in multimedia"
        :item="row"
      ></MultimediaListItem>
      <CaInfiniteScroll
        v-if="multimedia.length > 1 && !noMorePages"
        @triggered="fetchMore"
      />

      <div
        v-if="
          useMultimediaStore().fetchMultimediaRequest.isLoading &&
          !onFirstPage &&
          !noMorePages
        "
        class="mt-20 w-full text-center"
      >
        <CaSpinner class="m-auto"></CaSpinner>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
