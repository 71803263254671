<script setup lang="ts">
import CaIcon from "~/components/ui/CaIcon.vue";
import { useGeneralStore } from "~/store/useGeneralStore";

const store = useGeneralStore();
function togglePrivacyBlur() {
  store.blurCurrency = !store.blurCurrency;
}
</script>

<template>
  <span data-testid="navbar-button-privacyToggle" @click="togglePrivacyBlur">
    <CaIcon v-if="store.blurCurrency" name="Eye-off"></CaIcon>
    <CaIcon v-else name="Eye-on"></CaIcon>
  </span>
</template>

<style scoped lang="scss"></style>
