<script setup lang="ts">
import PortfolioCard from "~/components/PortfolioCard.vue";
import Carousel from "~/components/carousel/Carousel.vue";
import CaIcon from "~/components/ui/CaIcon.vue";
import CurrencySpan from "~/components/CurrencySpan.vue";
import YourContentList from "~/components/YourContentList.vue";
import AccountExplanationModal from "~/components/modals/AccountExplanationModal.vue";
import { useAccountsStore } from "~/store/useAccountsStore";
import NoAccountsImage from "~/assets/no-accounts.svg";
import AddToHomeScreenModal from "~/components/modals/AddToHomeScreenModal.vue";
import { isThisAMobileDevice, isThisAnIPhone } from "~/helpers";
import { useLocalStorage } from "@vueuse/core";
import { useGeneralStore } from "~/store/useGeneralStore";
import { usePosthogEvent } from "~/composables/usePosthogEvent";

const userAccount = computed(() => accountsStore.fetchAllRequest.result);

const addToHomescreenDoNotAskAgain = useLocalStorage(
  "addToHomescreenDoNotAskAgain",
  false,
);

const generalStore = useGeneralStore();
const accountsStore = useAccountsStore();
const multimediaStore = useMultimediaStore();
const posthogEvent = usePosthogEvent();

const route = useRoute();

function accountExplanation() {
  generalStore.openModal("accountExplanationModal");
  posthogEvent.dashboardHelpClicked();
}

onMounted(async () => {
  if (!generalStore.iosAppAvailableModalFinished && isThisAnIPhone()) {
    generalStore.openModal("AppAvailableModal");
    generalStore.iosAppAvailableModalFinished = true;
  } else if (!addToHomescreenDoNotAskAgain.value && isThisAMobileDevice()) {
    generalStore.openModal("AddToHomescreenModal");
    addToHomescreenDoNotAskAgain.value = true;
  }
  if (!userAccount.value?.accounts) {
    await accountsStore.fetch();
  }
  if (!route.params.keepFilter) {
    await multimediaStore.clearFilter();
    multimediaStore.saveFilter();
    await multimediaStore.filter();
  }
});
</script>

<script lang="ts">
import { useMultimediaStore } from "~/store/useMultimediaStore";

export default {
  async beforeRouteEnter(to, from) {
    if (from.params.cameFrom === to.fullPath) {
      to.params.keepFilter = "true";
    }
    return true;
  },
};
</script>

<route lang="yaml">
meta:
  screenName: app_home
</route>

<template>
  <div
    v-if="
      accountsStore.fetchAllRequest.isLoading ||
      multimediaStore.isFetchingFilterData
    "
    class="mt-20 text-center"
  >
    <CaSpinner class="m-auto"></CaSpinner>
  </div>

  <div v-else-if="accountsStore.fetchAllRequest.error">
    <HttpError :error="accountsStore.fetchAllRequest.error"></HttpError>
  </div>

  <div v-else>
    <AddToHomeScreenModal />
    <AppAvailableModal />
    <AccountExplanationModal></AccountExplanationModal>
    <div class="relative px-4 pb-8 pt-10 sm:px-6 lg:px-0">
      <div
        class="mb-2 flex items-center justify-between gap-2 lg:justify-start"
      >
        <div class="text-xl font-light">
          {{ $t("accounts_title") }}
        </div>
        <CaIcon
          name="Tooltip-circle"
          class="cursor-pointer"
          data-testid="appHome-button-explain"
          @click="accountExplanation"
        ></CaIcon>
      </div>
      <CurrencySpan
        :value="userAccount?.totalInvestments"
        :currency="userAccount?.totalInvestmentsCurrency"
        class="text-3xl font-regular"
        data-testid="appHome-totalInvestments"
      />
    </div>

    <Carousel
      v-if="userAccount?.accounts?.length"
      :item-count="userAccount?.accounts?.length"
      :initialOffset="accountsStore.homeCarouselScrolledPosition"
      @store-offset="accountsStore.homeCarouselScrolledPosition = $event"
    >
      <template
        v-for="(portfolio, idx) in userAccount?.accounts"
        #[`carouselItem-${idx+1}`]
      >
        <PortfolioCard
          :account="portfolio"
          :data-testid="`appHome-portfolioCards-${idx}`"
          @click="$router.push(`/accounts/${portfolio.id}`)"
        />
      </template>
    </Carousel>

    <ErrorState
      v-else
      class="h-[370px]"
      :message="$t('empty_state_accounts_title')"
    >
      <template #image>
        <NoAccountsImage alt="No accounts" class="m-auto" />
      </template>
    </ErrorState>

    <YourContentList />
  </div>
</template>
