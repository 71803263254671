import posthog from "posthog-js";
import { UserModule } from "~/types/app";

export const install: UserModule = ({ app }) => {
  app.config.globalProperties.$posthog = posthog.init(
    import.meta.env.VITE_POSTHOG_TOKEN,
    {
      api_host: import.meta.env.VITE_POSTHOG_ADDRESS,
      autocapture: false,
      capture_pageview: false,
      capture_pageleave: false,
      session_recording: {
        maskTextSelector: "*",
      },
    },
  );
};
