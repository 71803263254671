import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  isSupported,
  Messaging,
  onMessage,
} from "firebase/messaging";
import loadFirebaseConfig from "~/config/loadFirebaseConfig";
import { router } from "./router";

const firebaseConfig = await loadFirebaseConfig();

const app = initializeApp(firebaseConfig);

let messaging: Messaging;

try {
  const isSupportedBrowser = await isSupported();
  if (isSupportedBrowser) {
    messaging = getMessaging(app);

    onMessage(messaging, (payload) => {
      console.log("Received foreground message ", payload);

      useToast().notification(
        payload.notification?.title,
        payload.notification?.body,
        () => onNotificationClick({ target: payload }),
      );
    });
  } else {
    console.log("Firebase not supported this browser");
  }
} catch (err) {
  console.log(err);
}

async function onNotificationClick(event: any) {
  const notificationData = event.target.data;
  if (notificationData.type === "MULTIMEDIA_PUBLISHED") {
    await router.push({
      name: "multimedia-id",
      params: { id: notificationData.multimediaId },
      query: { source: "notification" },
    });
  }
}

async function initMessaging() {
  if (!messaging) {
    return "";
  }
  const token = await getToken(messaging, {
    vapidKey: import.meta.env.VITE_FIREBASE_VAPID_KEY,
  });

  if (token) {
    return token;
  }
  return "";
}

export { initMessaging };
