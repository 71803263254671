import { ApiKy } from "./index";
import { PaginatedResponse } from "~/types/PaginatedResponse";
import { Multimedia } from "~/types/multimedia/Multimedia";
import { Author } from "~/types/multimedia/Author";
import { MultimediaTag } from "~/types/multimedia/MultimediaTag";
import { VisualGroup } from "~/types/account/VisualGroup";
import { MultimediaSearch } from "~/types/MultimediaSearch";
import { queryObjectToString } from "~/helpers";
import { MultimediaReactionType } from "~/types/multimedia/MultimediaReactionType";
import { MultimediaReaction } from "~/types/multimedia/MultimediaReaction";
import { Options } from "ky";

export default class ApiMultimedia extends ApiKy {
  fetchOne = (id: string) => {
    return this.ky.get(`${this.url}v1/multimedia/${id}`).json<Multimedia>();
  };
  fetchPreview = (id: string) => {
    return this.ky
      .get(`${this.url}v1/multimedia/${id}/preview`)
      .json<Multimedia>();
  };
  search = (query: string) => {
    return this.ky
      .get(`${this.url}v2/multimedia`, {
        searchParams: {
          search: query,
        },
      })
      .json<PaginatedResponse<Multimedia>>();
  };

  filter = (query: MultimediaSearch, options: Options) => {
    return this.ky
      .get(`${this.url}v2/multimedia`, {
        searchParams: queryObjectToString(query),
        ...options,
      })
      .json<PaginatedResponse<Multimedia>>();
  };
  authors = (query: MultimediaSearch, options: Options) => {
    return this.ky
      .get(`${this.url}v2/multimedia-authors`, {
        searchParams: queryObjectToString(query),
        ...options,
      })
      .json<Author[]>();
  };
  tags = (query: MultimediaSearch, options: Options) => {
    return this.ky
      .get(`${this.url}v1/multimedia-tags`, {
        searchParams: queryObjectToString(query),
        ...options,
      })
      .json<MultimediaTag[]>();
  };
  visualGroups = (query: MultimediaSearch, options: Options) => {
    return this.ky
      .get(`${this.url}v1/multimedia-visual-groups`, {
        searchParams: queryObjectToString(query),
        ...options,
      })
      .json<VisualGroup[]>();
  };

  read = (id: string) => {
    return this.ky.post(`${this.url}v1/multimedia/${id}/read`);
  };

  react = (id: string, type: MultimediaReactionType) => {
    return this.ky
      .post(`${this.url}v1/multimedia/${id}/reactions`, {
        json: { reactionType: type },
      })
      .json<MultimediaReaction>();
  };

  tellMeMore = (id: string, question: string) => {
    return this.ky.post(`${this.url}v1/multimedia/${id}/tell-me-more`, {
      json: { question },
    });
  };

  deleteReact = (id: string, reactId: string) => {
    return this.ky.delete(
      `${this.url}v1/multimedia/${id}/reactions/${reactId}`,
    );
  };
}
