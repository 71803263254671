import i18next from "i18next";
import en from "~/assets/i18n/webapp.en-GB.json";
import { UserModule } from "~/types/app";
import I18NextVue from "i18next-vue";

export const install: UserModule = ({ app }) => {
  i18next.init({
    debug: false,
    lng: "en",
    interpolation: {
      escapeValue: false,
    },
    fallbackLng: false,
    returnNull: false,
    resources: {
      en: {
        translation: en,
      },
    },
  });

  app.use(I18NextVue, { i18next });
};
