import { createRouter, createWebHistory } from "vue-router";
import { setupLayouts } from "virtual:generated-layouts";
import generatedRoutes from "virtual:generated-pages";
import { useAuthStore } from "~/store/useAuthStore";
import posthog from "posthog-js";
import { useThrottleFn } from "@vueuse/core";

const routes = setupLayouts(generatedRoutes);
const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
  routes,
});

function getQueryFromUrl(name: string) {
  return router.currentRoute.value.query[name]?.toString() ?? "";
}

const throttledFetch = useThrottleFn(() => {
  useGeneralStore().fetchInformativeMessage().then();
}, 10000);

/**
 * Navigation Guards
 */
router.beforeEach((to, from) => {
  const authStore = useAuthStore();
  if (to.meta.publicPage) {
    if (authStore.user && to.path.includes("/auth/")) {
      return { name: "" }; // User is already logged in, auth pages should redirect to Dashboard
    } else {
      return true;
    }
  } else {
    if (!authStore.user) {
      posthog.capture("Session expired");
      return { name: "auth-login", query: { redirect: to.fullPath } }; // User is not logged in, redirect to the login page
    } else {
      throttledFetch().then();
      return true;
    }
  }
});

router.afterEach((to) => {
  nextTick(() => {
    const authStore = useAuthStore();
    if (authStore.user) {
      posthog.capture("$pageview");
    }
  });
});

export { router, routes, getQueryFromUrl };
