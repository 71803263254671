import { ApiKy } from "./index";
import { DocumentFolder } from "~/types/DocumentFolder";
import { Document } from "~/types/Document";

export default class ApiDocuments extends ApiKy {
  fetchAll = () => {
    return this.ky.get(`${this.url}/document-folders`).json<DocumentFolder[]>();
  };
  fetchOne = (id: string) => {
    return this.ky.get(`${this.url}/documents/${id}`).json<Document>();
  };
  download = (id: string) => {
    return this.ky.get(`${this.url}/documents/${id}/download`).arrayBuffer();
  };
}
