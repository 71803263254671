import { ApiKy } from "./index";
import { BalanceSheet } from "~/types/balanceSheet/BalanceSheet";

export default class ApiBalanceSheets extends ApiKy {
  fetchAll = () => {
    return this.ky.get(`${this.url}/balance-sheets`).json<BalanceSheet[]>();
  };
  fetchOne = (id: string) => {
    return this.ky.get(`${this.url}/balance-sheets/${id}`).json<BalanceSheet>();
  };
  fetchPreview = (id: string) => {
    return this.ky
      .get(`${this.url}/balance-sheets/${id}/preview`)
      .json<BalanceSheet>();
  };
  tellMeMore = (question: string) => {
    return this.ky.post(`${this.url}/balance-sheets/tell-me-more`, {
      json: { question },
    });
  };
}
