<script setup lang="ts">
import { onClickOutside, useToggle } from "@vueuse/core";
import CaIcon from "~/components/ui/CaIcon.vue";

type DropdownItem = {
  label: string;
  value: string;
};

interface Props {
  label?: string;
  items?: DropdownItem[];
}
withDefaults(defineProps<Props>(), {
  items: () => [] as DropdownItem[],
});

const emit = defineEmits(["selected"]);

const [shown, toggle] = useToggle();

function onClick(item: DropdownItem) {
  emit("selected", item.value);
  shown.value = false;
}

const wrapper = ref(null);
onClickOutside(wrapper, (_) => (shown.value = false));
</script>

<template>
  <div ref="wrapper">
    <div
      class="cursor-pointer"
      data-testclass="dropdown-toggle"
      @click="toggle()"
    >
      <slot>
        <span>
          {{ label }}
          <CaIcon
            v-if="shown"
            name="Chevron top"
            class="mb-1 inline-block !h-4 align-middle"
          ></CaIcon>
          <CaIcon
            v-else
            name="Chevron down"
            class="mb-1 inline-block !h-4 align-middle"
          ></CaIcon>
        </span>
      </slot>
    </div>
    <div
      v-show="shown"
      class="absolute z-[1000] -ml-2 mt-1 border border-t-0 border-dark-gray border-opacity-20 bg-light-gray px-6 py-1"
      data-testclass="dropdown-menu"
    >
      <ul>
        <li
          v-for="item in items"
          class="cursor-pointer border-b border-dark-gray border-opacity-10 py-3 font-light last:border-b-0"
          data-testclass="dropdown-menu-item"
          @click="onClick(item)"
        >
          {{ item.label }}
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
