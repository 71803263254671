import { defineStore } from "pinia";
import { useHttpClient } from "~/composables/http-client";
import { Multimedia } from "~/types/multimedia/Multimedia";
import {
  MultimediaFilterState,
  MultimediaSearch,
} from "~/types/MultimediaSearch";
import { MultimediaReadStatus } from "~/types/multimedia/MultimediaReadStatus";
import { ContentType } from "~/types/multimedia/ContentType";
import { MultimediaReactionType } from "~/types/multimedia/MultimediaReactionType";
import { PaginatedMeta } from "~/types/PaginatedMeta";
import { useApi } from "~/composables/useApi";

function filterStateDefault() {
  return {
    accountId: undefined,
    moduleId: undefined,
    authors: {},
    tags: {},
    visualGroups: {},
    general: undefined,
    sort: {
      DESC: true,
      ASC: false,
    },
    readStatus: {
      READ: false,
      UNREAD: false,
    },
    contentTypes: {
      RICH: false,
      STATIC: false,
    },
  } as MultimediaFilterState;
}

export const useMultimediaStore = defineStore("multimedia", {
  state: () => ({
    multimedia: {} as Multimedia[],

    paging: {} as PaginatedMeta,

    searchQuery: "",
    filterState: filterStateDefault(),
    cachedFilterParams: "" as string,
    defaultFilterForCurrentPage: filterStateDefault(),

    filterActive: false,

    fetchMultimediaRequest: useApi(useHttpClient().multimedia.filter),

    fetchAuthorsRequest: useApi(useHttpClient().multimedia.authors),
    fetchTagsRequest: useApi(useHttpClient().multimedia.tags),
    fetchVisualGroupsRequest: useApi(useHttpClient().multimedia.visualGroups),

    fetchOneRequest: useApi(useHttpClient().multimedia.fetchOne),
    fetchPreviewRequest: useApi(useHttpClient().multimedia.fetchPreview),
    searchRequest: useApi(useHttpClient().multimedia.search),

    readRequest: useApi(useHttpClient().multimedia.read),
    reactRequest: useApi(useHttpClient().multimedia.react),
    deleteReactionRequest: useApi(useHttpClient().multimedia.deleteReact),
    tellMeMoreRequest: useApi(useHttpClient().multimedia.tellMeMore),
  }),

  getters: {
    current: (state) => state.fetchOneRequest.result,
    authors: (state) => state.fetchAuthorsRequest.result,
    tags: (state) => state.fetchTagsRequest.result,
    visualGroups: (state) => state.fetchVisualGroupsRequest.result,

    isFetchingFilterData: (state) =>
      state.fetchAuthorsRequest.isLoading ||
      state.fetchTagsRequest.isLoading ||
      state.fetchVisualGroupsRequest.isLoading,

    noMorePages: (state) =>
      Math.ceil(state.paging.total / state.paging.perPage) <= state.paging.page,

    onFirstPage: (state) => !state.paging?.page,
  },

  actions: {
    async fetchOne(id: string) {
      this.fetchPreviewRequest.reset();
      await this.fetchOneRequest.execute(id);
    },
    async fetchPreview(id: string) {
      this.fetchOneRequest.reset();
      await this.fetchPreviewRequest.execute(id);
    },
    async search(query: string) {
      await this.searchRequest.execute(query);
    },
    async filter(userInitiated = false, nextPage = false) {
      if (userInitiated) {
        this.filterActive = true;
      }

      const searchParams = buildSearchParams(
        this.filterState,
        nextPage ? this.paging.page + 1 : 1,
      );
      const searchParamsStringified = JSON.stringify(searchParams);

      if (this.cachedFilterParams === searchParamsStringified) {
        return;
      }
      if (!nextPage) {
        this.paging = {} as PaginatedMeta;
      }
      if (!userInitiated && !nextPage) {
        this.fetchFilterData(searchParams);
      }
      await this.fetchMultimediaRequest.execute(searchParams, {
        signal: this.fetchMultimediaRequest.newAbortSignal(),
      });
      if (this.fetchMultimediaRequest.result) {
        this.paging = this.fetchMultimediaRequest.result.meta;
        this.cachedFilterParams = searchParamsStringified;

        if (!nextPage) {
          this.multimedia = [];
        }
        this.multimedia.push(...this.fetchMultimediaRequest.result.data);
      }
    },
    fetchFilterData(searchParams: MultimediaSearch) {
      return Promise.all([
        this.fetchAuthorsRequest.execute(searchParams, {
          signal: this.fetchAuthorsRequest.newAbortSignal(),
        }),
        this.fetchTagsRequest.execute(searchParams, {
          signal: this.fetchTagsRequest.newAbortSignal(),
        }),
        this.fetchVisualGroupsRequest.execute(searchParams, {
          signal: this.fetchVisualGroupsRequest.newAbortSignal(),
        }),
      ]);
    },
    async react(type: MultimediaReactionType) {
      if (this.fetchOneRequest.result) {
        try {
          await this.reactRequest.execute(this.fetchOneRequest.result.id, type);
          await this.fetchOneRequest.backgroundExecute(
            this.fetchOneRequest.result.id,
          );
        } catch {}
      }
    },
    async tellMeMore(question = "") {
      if (this.fetchOneRequest.result) {
        await this.tellMeMoreRequest.execute(
          this.fetchOneRequest.result.id,
          question,
        );
      }
    },
    async read() {
      if (this.fetchOneRequest.result) {
        await this.readRequest.execute(this.fetchOneRequest.result.id);
        this.fetchOneRequest.result.read = true;
        const current = this.multimedia?.find(
          (m) => m.id === this.fetchOneRequest.result?.id,
        );
        if (current) {
          current.read = true;
        }
      }
    },
    async deleteReact() {
      if (this.fetchOneRequest.result && this.fetchOneRequest.result.reaction) {
        try {
          await this.deleteReactionRequest.execute(
            this.fetchOneRequest.result.id,
            this.fetchOneRequest.result.reaction.id,
          );
          await this.fetchOneRequest.backgroundExecute(
            this.fetchOneRequest.result.id,
          );
        } catch {}
      }
    },
    saveFilter() {
      this.defaultFilterForCurrentPage = JSON.parse(
        JSON.stringify(this.filterState),
      );
    },
    async restoreFilter() {
      this.filterActive = false;
      this.filterState = JSON.parse(
        JSON.stringify(this.defaultFilterForCurrentPage),
      );
    },
    async clearFilter() {
      this.filterActive = false;
      this.filterState = filterStateDefault();
    },
  },
});

function filterKeys(object: Record<string, boolean>) {
  return Object.keys(object).filter((k: string) => object[k]);
}

function buildSearchParams(filterState: MultimediaFilterState, page = 1) {
  const searchParams = {} as MultimediaSearch;
  searchParams.perPage = 10;
  searchParams.page = page;
  searchParams.sortDirection = filterState.sort.DESC ? "DESC" : "ASC";

  if (filterState.general) {
    searchParams.general = filterState.general;
  }

  if (filterState.accountId) {
    searchParams.accountId = filterState.accountId;
  }
  if (filterState.moduleId) {
    searchParams.moduleId = filterState.moduleId;
  }
  searchParams.tags = filterKeys(filterState.tags);
  searchParams.authorIds = filterKeys(filterState.authors);
  searchParams.visualGroupIds = filterKeys(filterState.visualGroups);
  searchParams.readStatuses = filterKeys(
    filterState.readStatus,
  ) as MultimediaReadStatus[];
  searchParams.contentTypes = filterKeys(
    filterState.contentTypes,
  ) as ContentType[];
  return searchParams;
}
