type NavigationItem = {
  title: string;
  link?: string;
  children?: NavigationItem[];
};

export function useNavbarHelpers() {
  const route = useRoute();

  const showTogglePerformance = computed(() => {
    return (
      route.fullPath === "/" ||
      route.fullPath.toLowerCase().includes("/accounts/")
    );
  });

  const showToggleBlur = computed(() => {
    return (
      route.fullPath === "/" ||
      route.fullPath.toLowerCase().includes("/accounts/") ||
      route.fullPath.toLowerCase().includes("/balancesheets")
    );
  });

  function shouldItemBeActive(navigationItem: NavigationItem) {
    if (navigationItem.link === "/") {
      return (
        route.path === "/" ||
        route.path.toLowerCase().startsWith("/accounts") ||
        route.path.toLowerCase().startsWith("/multimedia") ||
        route.path.toLowerCase().startsWith("/modules")
      );
    }
    if (navigationItem.link) {
      return route.path
        .toLowerCase()
        .startsWith(navigationItem.link.toLowerCase());
    }
    if (navigationItem.children) {
      return navigationItem.children.some(
        (i) =>
          i.link && route.path.toLowerCase().startsWith(i.link.toLowerCase()),
      );
    }
    return false;
  }

  return { showToggleBlur, showTogglePerformance, shouldItemBeActive };
}
