<script setup lang="ts">
const sizeClasses = {
  sm: "h-32",
  md: "h-48",
  lg: "h-64",
  xl: "h-96",
  full: "h-full",
};

interface Props {
  size?: keyof typeof sizeClasses;
}
const props = withDefaults(defineProps<Props>(), {
  size: "md",
});

const sizeClass = computed(() => {
  return sizeClasses[props.size];
});
</script>

<template>
  <div
    class="absolute -z-10 w-full min-w-[300px] bg-light-gray"
    :class="sizeClass"
  ></div>
</template>

<style scoped lang="scss"></style>
