import { TokenResponse } from "~/types/auth";
import * as webauthnJson from "@github/webauthn-json";
import { ApiKy } from "./index";
import {
  CredentialCreationOptionsJSON,
  CredentialRequestOptionsJSON,
} from "@github/webauthn-json";
import { Authenticator } from "~/types/Authenticator";

type WebAuthnRegsitrationResponse = {
  registrationId: string;
  publicKeyCredentialCreationOptions: CredentialCreationOptionsJSON;
};

type WebAuthnAuthenticationResponse = {
  authenticationId: string;
  publicKeyCredentialRequestOptions: CredentialRequestOptionsJSON;
};
export default class ApiWebauthn extends ApiKy {
  fetchAuthenticators = () => {
    return this.ky
      .get(`${this.url}/webauthn/authenticators`)
      .json<Authenticator[]>();
  };
  deleteAuthenticator = (id: string) => {
    return this.ky.delete(`${this.url}/webauthn/authenticators/${id}`);
  };
  register = async () => {
    const data = await this.ky
      .post(`${this.url}/webauthn/registrations`)
      .json<WebAuthnRegsitrationResponse>();

    const credential = await webauthnJson.create(
      data.publicKeyCredentialCreationOptions,
    );
    return this.ky
      .post(
        `${this.url}/webauthn/registrations/${data.registrationId}/finish`,
        {
          json: { credential },
        },
      )
      .json<TokenResponse>();
  };
  authenticate = async (email: string) => {
    const data = await this.ky
      .post(`${this.url}/webauthn/authentications`, {
        json: { email },
      })
      .json<WebAuthnAuthenticationResponse>();
    if (
      !data.publicKeyCredentialRequestOptions?.publicKey?.allowCredentials
        ?.length
    ) {
      throw new Error("NO_DEVICE");
    }
    const credential = await webauthnJson.get(
      data.publicKeyCredentialRequestOptions,
    );

    return this.ky
      .post(
        `${this.url}/webauthn/authentications/${data.authenticationId}/finish`,
        {
          json: { email, credential },
        },
      )
      .json<TokenResponse>();
  };
}
