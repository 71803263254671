export function mixColors(color_1: string, color_2: string, weight = 50) {
  function decimal2hex(d: number) {
    return d.toString(16);
  }
  function hex2decimal(h: string) {
    return parseInt(h, 16);
  }

  let color = "#";

  // loop through each of the 3 hex pairs—red, green, and blue
  for (let i = 0; i <= 5; i += 2) {
    let v1 = hex2decimal(color_1.substring(i, i + 2)), // extract the current pairs
      v2 = hex2decimal(color_2.substring(i, i + 2)),
      // combine the current pairs from each source color, according to the specified weight
      combined = decimal2hex(Math.floor(v1 + (v2 - v1) * (weight / 100.0)));

    while (combined.length < 2) {
      combined = "0" + combined;
    } // prepend a '0' if combined results in a single digit

    color += combined;
  }

  return color;
}

export function queryObjectToString(
  obj: Record<
    string,
    string | number | boolean | string[] | number[] | boolean[]
  >,
) {
  let params = new URLSearchParams();
  for (let o in obj) {
    const val = obj[o];
    if (Array.isArray(val)) {
      val.forEach((item: string | number | boolean) => {
        params.append(`${o}`, `${item}`);
      });
    } else {
      params.append(`${o}`, `${val}`);
    }
  }

  return params.toString();
}

export function isThisAMobileDevice() {
  let hasTouchScreen = false;
  if ("maxTouchPoints" in navigator) {
    hasTouchScreen = navigator.maxTouchPoints > 0;
  } else if ("msMaxTouchPoints" in navigator) {
    hasTouchScreen = navigator["msMaxTouchPoints"] > 0;
  } else {
    const mQ = matchMedia?.("(pointer:coarse)");
    if (mQ?.media === "(pointer:coarse)") {
      hasTouchScreen = !!mQ.matches;
    } else if ("orientation" in window) {
      hasTouchScreen = true; // deprecated, but good fallback
    } else {
      // as a last resort, fall back to user agent sniffing
      const UA = navigator["userAgent"];
      hasTouchScreen =
        /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
        /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
    }
  }
  return hasTouchScreen;
}

export function isThisAnIPhone() {
  return (
    navigator.platform === "iPhone" || navigator.userAgent.includes("iPhone")
  );
}
export function isiOSDevice() {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
}
