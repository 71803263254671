import { defineStore } from "pinia";
import { useHttpClient } from "~/composables/http-client";
import { NotificationDevice } from "~/types/notificationDevice";
import * as messaging from "~/firebase";

export const useNotificationsStore = defineStore("notifications", {
  state: () => ({
    devices: [] as NotificationDevice[],
    token: "",
  }),

  getters: {
    currentNotificationDevice: (state) =>
      state.devices.find((d) => d.token === state.token),
  },

  actions: {
    async obtainPermission() {
      if ("Notification" in window) {
        await Notification.requestPermission();
      }
    },

    async initMessaging() {
      this.token = await messaging.initMessaging();
    },

    async fetchDevices() {
      try {
        this.devices = await useHttpClient().notificationDevices.fetchAll();
      } catch (e) {}
    },

    async initialize() {
      await Promise.all([this.fetchDevices(), this.initMessaging()]);

      if (this.token && !this.currentNotificationDevice) {
        useHttpClient().notificationDevices.save({
          name: navigator.userAgent,
          token: this.token,
          platform: "WEB",
        });
      }
    },

    async logout() {
      const id = this.currentNotificationDevice?.id;
      if (id) {
        return useHttpClient().notificationDevices.delete(id);
      }
    },
  },
});
