<script setup lang="ts"></script>

<template>
  <div class="fixed left-1 top-20 z-20 rounded bg-white py-1">
    <ul>
      <li
        class="text-darker-gray cursor-pointer px-2 py-0.5 text-center font-medium hover:bg-light-gray"
        title="Break Access Token"
        @click="useTokenStore().breakAccessToken"
      >
        AT
      </li>
      <li
        class="text-darker-gray cursor-pointer px-2 py-0.5 text-center font-medium hover:bg-light-gray"
        title="Break Refresh Token"
        @click="useTokenStore().breakRefreshToken"
      >
        RT
      </li>
      <hr />
      <li
        class="text-darker-gray cursor-pointer px-2 py-0.5 text-center font-medium hover:bg-light-gray"
        title="Show a toast"
        @click="useToast().success('Success', 'This is a test success toast')"
      >
        <CaIcon name="Toast"></CaIcon>
      </li>
    </ul>
  </div>
</template>

<style scoped lang="scss"></style>
