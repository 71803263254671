import { User } from "~/types/user/User";
import { ApiKy } from "./index";
import { Referral } from "~/types/Referral";
import { Office } from "~/types/Office";
import { Adviser } from "~/types/Adviser";
import { InformativeMessage } from "~/types/InformativeMessage";

export default class ApiUsers extends ApiKy {
  getUserData = () => {
    return this.ky.get(`${this.url}/users/me`).json<User>();
  };
  fetchAdvisors = () => {
    return this.ky.get(`${this.url}/advisors`).json<Adviser[]>();
  };
  fetchOffice = () => {
    return this.ky.get(`${this.url}/office`).json<Office>();
  };
  refer = (body: Referral) => {
    return this.ky.post(`${this.url}/referrals`, { json: body });
  };
  deleteAccount = (message: string) => {
    return this.ky.post(`${this.url}/users/me/request-to-delete`, {
      json: {
        message: message,
      },
    });
  };
  fetchInformativeMessage = () => {
    return this.ky
      .get(`${this.url}/informative-message`)
      .json<InformativeMessage>();
  };
}
