<script setup lang="ts">
import { useGeneralStore } from "~/store/useGeneralStore";

interface Props {
  name: string;
  text?: string;
  showCancel?: boolean;
  acceptText?: string;
}
const props = withDefaults(defineProps<Props>(), {
  text: "Please confirm this action.",
  showCancel: true,
  acceptText: "Confirm",
});

const emit = defineEmits(["accepted"]);

function close() {
  useGeneralStore().closeModal(props.name);
}
function accept() {
  emit("accepted", useGeneralStore().modals[props.name]?.data);
  close();
}
function cancel() {
  close();
}
</script>

<template>
  <CaModalWindow
    :name="name"
    modal-class="mx-2 rounded-md sm:mx-auto sm:max-w-lg"
  >
    <div
      class="mb-6 max-h-[calc(100vh-272px)] overflow-auto"
      data-testclass="confirmationDialog-text"
    >
      <slot>
        {{ text }}
      </slot>
    </div>
    <div class="flex justify-between gap-4">
      <CaButton
        v-if="showCancel"
        color="secondary"
        block
        data-testclass="confirmationDialog-button-cancel"
        @click="cancel"
        >Cancel</CaButton
      >
      <CaButton
        color="primary"
        block
        data-testclass="confirmationDialog-button-accept"
        @click="accept"
        >{{ acceptText }}</CaButton
      >
    </div>
  </CaModalWindow>
</template>

<style scoped lang="scss"></style>
