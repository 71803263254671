import { AuthResponse, TokenResponse } from "~/types/auth";
import { ApiKy } from "./index";

export default class ApiAuth extends ApiKy {
  sendSms = (email: string) => {
    return this.ky
      .post(`${this.url}/auth`, {
        json: { email },
        credentials: "omit",
      })
      .json<AuthResponse>();
  };
  fetchToken = (email: string, pin: string) => {
    return this.ky
      .post(`${this.url}/auth/token`, {
        json: {
          email,
          pin,
        },
        credentials: "omit",
      })
      .json<TokenResponse>();
  };
  refreshToken = (email: string, refreshToken: string) => {
    return this.ky
      .post(`${this.url}/auth/token/refresh`, {
        json: {
          refreshToken,
          email,
        },
        credentials: "omit",
      })
      .json<TokenResponse>();
  };
  revokeToken = (refreshToken: string) => {
    return this.ky.post(`${this.url}/auth/token/revoke-refresh`, {
      json: {
        refreshToken,
      },
      retry: 0,
      parseJson(text) {
        return "";
      },
    });
  };
}
