<script setup lang="ts">
const emit = defineEmits(["itemEntered", "itemLeft"]);

const intersectionWithViewportCallback = (
  entries: IntersectionObserverEntry[],
  observer: IntersectionObserver
) => {
  entries.forEach((entry: IntersectionObserverEntry) => {
    if (entry.intersectionRatio === 1) {
      emit("itemEntered");
    } else {
      emit("itemLeft");
    }
  });
};

const observer = new IntersectionObserver(intersectionWithViewportCallback, {
  root: null,
  rootMargin: "0px",
  threshold: 1,
});

const item = ref<HTMLDivElement>();

onMounted(() => {
  if (item.value) {
    observer.observe(item.value);
  }
});
</script>

<template>
  <div class="carousel-item" ref="item">
    <slot></slot>
  </div>
</template>

<style scoped lang="scss"></style>
