import { defineStore } from "pinia";
import { useHttpClient } from "~/composables/http-client";
import { Account } from "~/types/account/Account";
import { Investment } from "~/types/account/Investment";
import { uniq } from "lodash-es";

const cardBackgroundsOrder = [
  "bg-portfolio-growth",
  "bg-portfolio-aggressive",
  "bg-portfolio-balanced",
  "bg-portfolio-cautious",
];

export const useAccountsStore = defineStore("accounts", {
  state: () => ({
    homeCarouselScrolledPosition: 0,
    fetchAllRequest: useApi(useHttpClient().accounts.fetch),
  }),

  getters: {
    getAccountById: (state) => (id: string) =>
      state.fetchAllRequest.result?.accounts?.find((acc) => acc.id === id),
    accountBackgrounds: (state) => {
      return (
        state.fetchAllRequest.result?.accounts?.reduce(
          (
            obj: Record<string, string>,
            account: Account,
            accountIdx: number,
          ) => {
            obj[account.id] = cardBackgroundsOrder[accountIdx % 4];
            return obj;
          },
          {},
        ) || {}
      );
    },
    myVisualGroups: (state) => {
      if (!state.fetchAllRequest.result?.accounts) {
        return [];
      }
      const investments: Investment[] =
        state.fetchAllRequest.result?.accounts?.reduce(
          (arr: Investment[], account) => {
            arr = arr.concat(account.investments);
            return arr;
          },
          [],
        );
      return uniq(investments.map((inv) => inv.module.visualGroup?.name));
    },
  },

  actions: {
    fetch() {
      return this.fetchAllRequest.execute();
    },
  },
});
