import { defineStore } from "pinia";
import { useStorage } from "@vueuse/core";
import { computed } from "vue";

export const useTokenStore = defineStore("tokens", () => {
  let tokens = useStorage("tokens", {
    accessToken: "",
    refreshToken: "",
    email: "",
  });

  const getTokens = computed(() => {
    return {
      accessToken: tokens.value.accessToken,
      refreshToken: tokens.value.refreshToken,
      email: tokens.value.email,
    };
  });

  function setTokens(
    accessToken: string,
    refreshToken: string,
    email?: string,
  ) {
    if (!accessToken || !refreshToken) return;
    tokens.value.accessToken = accessToken;
    tokens.value.refreshToken = refreshToken;
    if (email) {
      tokens.value.email = email;
    }
  }

  function clearTokens() {
    tokens.value.accessToken = "";
    tokens.value.refreshToken = "";
    tokens.value.email = "";
  }

  function breakAccessToken() {
    tokens.value.accessToken = "abcdef";
  }
  function breakRefreshToken() {
    tokens.value.refreshToken = "abcdef";
  }

  return {
    getTokens,
    setTokens,
    clearTokens,
    breakAccessToken,
    breakRefreshToken,
  };
});
