interface UseApiResult<
  T,
  TExecuteFactory extends (...args: any) => Promise<T | undefined>,
  TError = any,
> {
  result: Ref<T | null>;
  isLoading: Ref<boolean>;
  error: Ref<TError | null>;
  execute: TExecuteFactory;
  backgroundExecute: TExecuteFactory;
  newAbortSignal: () => AbortSignal;
  reset: () => void;
}

export function useApi<TResult, TArgs extends any[]>(
  request: (...args: TArgs) => Promise<TResult>,
): UseApiResult<TResult, (...args: TArgs) => Promise<TResult | undefined>> {
  const isLoading = ref(false);
  const result = ref<TResult | null>(null) as Ref<TResult | null>;
  const error = ref(null);
  const abortController = ref(null as null | AbortController);

  const execute = async (...args: TArgs) => {
    isLoading.value = true;
    error.value = null;
    try {
      const response = await request(...args);
      result.value = response;
      return response;
    } catch (e: any) {
      error.value = e;
      result.value = null;
    } finally {
      isLoading.value = false;
      abortController.value = null;
    }
  };

  const backgroundExecute = async (...args: TArgs) => {
    error.value = null;
    try {
      const response = await request(...args);
      result.value = response;
      return response;
    } catch (e: any) {
      error.value = e;
      result.value = null;
    }
  };

  const newAbortSignal = () => {
    if (abortController.value) {
      abortController.value.abort("replaced");
    }
    abortController.value = new AbortController();
    return abortController.value.signal;
  };

  const reset = () => {
    isLoading.value = false;
    result.value = null;
    error.value = null;
  };

  return {
    isLoading,
    result,
    error,
    execute,
    backgroundExecute,
    newAbortSignal,
    reset,
  };
}
