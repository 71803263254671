

import __layout_0 from '/opt/atlassian/pipelines/agent/build/src/layouts/default.vue'
export const layouts = {
'auth': () => import('/opt/atlassian/pipelines/agent/build/src/layouts/auth.vue'),
'default': __layout_0,
'multimedia': () => import('/opt/atlassian/pipelines/agent/build/src/layouts/multimedia.vue'),
'page': () => import('/opt/atlassian/pipelines/agent/build/src/layouts/page.vue'),
'plain': () => import('/opt/atlassian/pipelines/agent/build/src/layouts/plain.vue'),
'public': () => import('/opt/atlassian/pipelines/agent/build/src/layouts/public.vue'),
'window': () => import('/opt/atlassian/pipelines/agent/build/src/layouts/window.vue'),
}

export function setupLayouts(routes) {
  return routes.map(route => {
    return { 
      path: route.path,
      meta: route.meta,
      component: layouts[route.meta?.layout || 'default'],
      children: route.path === '/' ? [route] : [{...route, path: ''}]
    }
  })
}
