<script setup lang="ts">
import CaIcon from "~/components/ui/CaIcon.vue";
import { useGeneralStore } from "~/store/useGeneralStore";

const store = useGeneralStore();
const posthogEvent = usePosthogEvent();

function toggleShowPerformance() {
  store.showPerformance = !store.showPerformance;
  posthogEvent.performanceEnabled(store.showPerformance);
}
</script>

<template>
  <span
    data-testid="navbar-button-performanceToggle"
    @click="toggleShowPerformance"
  >
    <img
      v-if="store.showPerformance"
      src="../../assets/sort.png"
      alt="Sort enabled"
      class="h-6 w-6"
    />
    <CaIcon v-else name="Sort"></CaIcon>
  </span>
</template>

<style scoped lang="scss"></style>
