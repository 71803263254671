<script setup lang="ts">
import { useLocalStorage } from "@vueuse/core/index";
import { useGeneralStore } from "~/store/useGeneralStore";

const cookiesDoNotAskAgain = useLocalStorage("cookiesDoNotAskAgain", false);

function consent() {
  cookiesDoNotAskAgain.value = true;
  useGeneralStore().closeModal("CookieConsentModal");
}

const generalStore = useGeneralStore();
onMounted(() => {
  if (!cookiesDoNotAskAgain.value) {
    generalStore.openModal("CookieConsentModal");
  }
});
</script>

<template>
  <CaModalWindow
    name="CookieConsentModal"
    no-backdrop-close
    modal-class="bottom-0 lg:left-auto py-6 lg:m-4 lg:rounded-md lg:shadow-2xl lg:max-w-lg"
  >
    <div class="mb-4 text-lg">We use cookies</div>
    <p class="mb-4 font-light">
      By using our site you agree to the storing of cookies on your device to
      enhance site navigation, analyze site usage and assist in our marketing
      efforts.
    </p>
    <div class="flex justify-end">
      <CaButton data-testid="cookieConsentModal-button-accept" @click="consent"
        >Accept</CaButton
      >
    </div>
  </CaModalWindow>
</template>

<style scoped lang="scss"></style>
