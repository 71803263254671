export abstract class Form {
  public data: Record<string, any> | any;
  public errors: Record<string, string>;

  constructor() {
    this.data = {};
    this.errors = {};
  }

  abstract init(): void;

  clear() {
    this.data = {};
    this.errors = {};
    this.init();
  }

  clearErrors(field = undefined) {
    if (field) {
      delete this.errors[field];
    } else {
      this.errors = {};
    }
  }

  serialize() {
    // remove null and empty string attributes
    return Object.fromEntries(
      Object.entries(this.data).filter(([_, v]) => v !== "" && v != null),
    );
  }
}
