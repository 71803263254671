<script setup lang="ts">
import { useAccountsStore } from "~/store/useAccountsStore";

const userAccount = computed(() => useAccountsStore().fetchAllRequest.result);

const included = computed(
  () =>
    userAccount.value?.accounts?.filter((a) => a.includedInTotalInvestments),
);
const excluded = computed(
  () =>
    userAccount.value?.accounts?.filter((a) => !a.includedInTotalInvestments),
);
</script>

<template>
  <CaConfirmationDialog
    name="accountExplanationModal"
    accept-text="Got it"
    :show-cancel="false"
  >
    <h3>{{ $t("accounts_explanation_title") }}</h3>
    <div class="mt-4 font-light">
      <p>{{ $t("accounts_explanation_text") }}</p>
      <hr class="my-4" />
      <p>{{ $t("accounts_explanation_included") }}</p>
      <ul class="list-inside list-disc">
        <li v-for="item in included">{{ item.name }}</li>
      </ul>
      <hr v-if="excluded?.length" class="my-4" />
      <p v-if="excluded?.length">
        {{ $t("accounts_explanation_excluded") }}
      </p>
      <ul class="list-inside list-disc">
        <li v-for="item in excluded">{{ item.name }}</li>
      </ul>
    </div>
  </CaConfirmationDialog>
</template>

<style scoped lang="scss"></style>
