import { ApiKy } from "./index";
import { Module } from "~/types/account/Module";

export default class ApiModules extends ApiKy {
  fetchOne = (id: string) => {
    return this.ky.get(`${this.url}/modules/${id}`).json<Module>();
  };

  fetchPreview = (id: string) => {
    return this.ky.get(`${this.url}/modules/${id}/preview`).json<Module>();
  };
}
