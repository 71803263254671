<script setup lang="ts">
import CaIcon from "~/components/ui/CaIcon.vue";
import PrivacyToggleIcon from "~/components/navigation/PrivacyToggleIcon.vue";
import PerformanceToggleIcon from "~/components/navigation/PerformanceToggleIcon.vue";
import TheSidebar from "~/components/navigation/TheSidebar.vue";
import { useTranslation } from "i18next-vue";
import CaDropdown from "~/components/ui/CaDropdown.vue";
import { useNavbarHelpers } from "~/composables/useNavbarHelpers";

const navigationLinks = [
  {
    title: useTranslation().t("menu_entry_portfolios"),
    link: "/",
  },
  {
    title: useTranslation().t("menu_entry_balance_sheets"),
    link: "/balanceSheets",
  },
  {
    title: useTranslation().t("menu_entry_profile"),
    children: [
      {
        title: useTranslation().t("menu_entry_me"),
        link: "/profile",
      },
      {
        title: useTranslation().t("menu_entry_advisers"),
        link: "/advisers",
      },
      {
        title: useTranslation().t("menu_entry_documents"),
        link: "/documents",
      },
    ],
  },
  {
    title: useTranslation().t("menu_entry_about"),
    children: [
      {
        title: useTranslation().t("menu_entry_who_we_are"),
        link: "/about",
      },
      {
        title: useTranslation().t("menu_entry_legal"),
        link: "/legal",
      },
    ],
  },
  {
    title: useTranslation().t("menu_entry_referral"),
    link: "/referral",
  },
];

let sidebarOpened = ref(false);

const { showTogglePerformance, showToggleBlur, shouldItemBeActive } =
  useNavbarHelpers();

function openLogoutModal() {
  useGeneralStore().openModal("logoutDialog");
}
async function logout() {
  await useAuthStore().logout();
}

watch(sidebarOpened, (newVal) => {
  if (newVal) {
    document.body.classList.add("!overflow-hidden");
  } else {
    document.body.classList.remove("!overflow-hidden");
  }
});
</script>

<template>
  <div>
    <TheSidebar
      :navigation-links="navigationLinks"
      :active="sidebarOpened"
      @closed="sidebarOpened = false"
    ></TheSidebar>
    <div
      class="flex h-16 items-center justify-between border-b border-dark-gray border-opacity-20 bg-light-gray px-4 sm:px-6 lg:h-24 lg:px-8"
    >
      <CaConfirmationDialog
        name="logoutDialog"
        text="Are you sure you want to log out?"
        @accepted="logout"
      ></CaConfirmationDialog>
      <div class="shrink-0 grow basis-0 lg:hidden">
        <CaIcon
          name="Menu"
          class="cursor-pointer"
          @click="sidebarOpened = true"
        ></CaIcon>
      </div>

      <RouterLink data-testid="navbar-logo" to="/"
        ><img src="/logo.svg" alt="CADRO" class="h-6"
      /></RouterLink>

      <div class="shrink-0 grow basis-0">
        <div class="flex justify-end gap-4 lg:gap-6">
          <PrivacyToggleIcon v-if="showToggleBlur" class="cursor-pointer" />
          <PerformanceToggleIcon
            v-if="showTogglePerformance"
            class="cursor-pointer"
          />
          <div class="hidden gap-6 lg:flex">
            <div
              v-if="showTogglePerformance || showToggleBlur"
              class="h-6 w-[1px] bg-medium-gray"
            ></div>
            <div
              v-for="link in navigationLinks"
              class="font-light"
              :class="{
                'font-regular': shouldItemBeActive(link),
              }"
            >
              <CaDropdown
                v-if="link.children"
                :label="link.title"
                :items="
                  link.children.map((c) => ({ label: c.title, value: c.link }))
                "
                data-testclass="navbar-menu-dropdown"
                @selected="$router.push($event)"
              >
              </CaDropdown>
              <RouterLink
                v-else
                data-testclass="navbar-menu-link"
                :to="link.link"
                >{{ link.title }}</RouterLink
              >
            </div>
            <div class="h-6 w-[1px] bg-medium-gray"></div>
            <CaIcon
              name="Logout red"
              class="cursor-pointer"
              data-testid="navbar-button-logout"
              @click="openLogoutModal"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
