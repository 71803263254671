<script setup lang="ts">
import { useAuthStore } from "~/store/useAuthStore";
import { User } from "~/types/user/User";
import { useNavbarHelpers } from "~/composables/useNavbarHelpers";

const navigationLinks = [
  {
    title: useTranslation().t("menu_entry_portfolios"),
    link: "/",
  },
  {
    title: useTranslation().t("menu_entry_profile"),
    link: "/profile",
  },
  {
    title: useTranslation().t("menu_entry_advisers"),
    link: "/advisers",
  },
  {
    title: useTranslation().t("menu_entry_documents"),
    link: "/documents",
  },
  {
    title: useTranslation().t("menu_entry_balance_sheets"),
    link: "/balanceSheets",
  },
  {
    title: useTranslation().t("menu_entry_referral"),
    link: "/referral",
  },
  {
    title: useTranslation().t("menu_entry_legal"),
    link: "/legal",
  },
  {
    title: useTranslation().t("menu_entry_about"),
    link: "/about",
  },
];

interface Props {
  active: boolean;
}
defineProps<Props>();

const emit = defineEmits(["closed"]);

const user = computed(() => useAuthStore().user as User);

const { shouldItemBeActive } = useNavbarHelpers();

function close() {
  emit("closed");
}

function openLogoutModal() {
  useGeneralStore().openModal("logoutDialog");
}
</script>

<template>
  <div class="sidebar-wrapper" :class="{ active: active }">
    <div class="sidebar-bg fixed z-10 h-full w-full bg-white"></div>
    <div
      class="sidebar fixed z-20 flex h-full w-full flex-col bg-white bg-opacity-50 py-6"
    >
      <CaIcon
        name="Close"
        class="mx-4 cursor-pointer sm:mx-6"
        data-testid="sidebar-button-close"
        @click="close"
      />
      <div class="flex items-center justify-between px-4 py-8 sm:px-6">
        <div class="text-xl" data-testid="sidebar-user">
          <span class="font-light">{{ user?.forename }}</span>
          <span class="">&nbsp;{{ user?.surname }}</span>
        </div>
        <RouterLink
          to="/profile"
          data-testid="sidebar-button-profile"
          @click="close"
        >
          <CaIcon class="shrink-0" name="Arrow right" />
        </RouterLink>
      </div>
      <div class="h-[1px] w-full bg-light-gray"></div>
      <div
        class="flex grow flex-col gap-2 overflow-y-auto px-4 py-8 text-lg sm:px-6"
      >
        <RouterLink
          v-for="link in navigationLinks"
          :to="link.link"
          class="cursor-pointer py-3"
          :class="{
            'font-medium': shouldItemBeActive(link),
          }"
          data-testclass="sidebar-menu-link"
          @click="close"
        >
          {{ link.title }}
        </RouterLink>
        <div
          class="cursor-pointer py-3 text-accent-red"
          data-testid="sidebar-button-logout"
          @click="openLogoutModal"
        >
          {{ $t("generic_labels_logout") }}
        </div>
      </div>
      <div class="h-[1px] w-full bg-light-gray"></div>
      <div class="mb-3 flex justify-between px-4 pt-8 sm:px-6">
        <img src="/logo.svg" alt="CADRO" class="h-5" />
        <div class="text-xs" data-testid="sidebar-version">v1.1</div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.sidebar-bg {
  background-image: url("~/assets/menu-bg.png");
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
}
.sidebar-wrapper {
  position: fixed;
  z-index: 30;
  left: -100%;
  transition: left 0.25s;

  &.active {
    left: 0;
  }
}
</style>
