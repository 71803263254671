import { ApiKy } from "./index";
import { NotificationDevice } from "~/types/notificationDevice";

export default class ApiNotificationDevices extends ApiKy {
  fetchAll = () => {
    return this.ky
      .get(`${this.url}/notification-devices`)
      .json<NotificationDevice[]>();
  };

  save = (body: NotificationDevice) => {
    return this.ky.post(`${this.url}/notification-devices`, { json: body });
  };

  delete = (id: string) => {
    return this.ky.delete(`${this.url}/notification-devices/${id}`);
  };
}
