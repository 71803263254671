<script setup lang="ts">
import { useGeneralStore } from "~/store/useGeneralStore";

interface Props {
  name: string;
  modalClass?: string;
  noBackdrop?: boolean;
  noBackdropClose?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  modalClass: "",
  noBackdrop: false,
  noBackdropClose: false,
});

const shown = computed(() => useGeneralStore().modals[props.name]?.shown);
function close() {
  if (!props.noBackdropClose) {
    useGeneralStore().closeModal(props.name);
  }
}
</script>

<template>
  <div>
    <Transition
      v-if="!noBackdrop"
      name="fade"
      enter-from-class="opacity-0"
      leave-to-class="opacity-0"
      enter-active-class="transition-all duration-200 ease-linear"
      leave-active-class="transition-all duration-200 ease-linear"
    >
      <div
        v-if="shown"
        class="fixed bottom-0 left-0 right-0 top-0 z-30 bg-black bg-opacity-20"
        @click="close"
      ></div>
    </Transition>

    <Transition
      name="up"
      enter-from-class="translate-y-10 opacity-0"
      leave-to-class="translate-y-10 opacity-0"
      enter-active-class="transition-all duration-300 ease-in-out"
      leave-active-class="transition-all duration-300 ease-in-out"
    >
      <div
        v-if="shown"
        class="fixed left-0 right-0 z-40 m-auto mt-4 bg-white px-8 py-12"
        :data-testclass="name"
        :class="modalClass"
      >
        <slot></slot>
      </div>
    </Transition>
  </div>
</template>

<style scoped lang="scss"></style>
