<script setup lang="ts">
defineProps({
  active: {
    type: Boolean,
    default: false,
  },
});

function clearHeight(el: Element) {
  const hel = el as HTMLElement;
  hel.style.height = "0";
}
function setHeight(el: Element) {
  const hel = el as HTMLElement;
  hel.style.height = el.scrollHeight + "px";
}
</script>

<template>
  <Transition
    @before-enter="clearHeight"
    @enter="setHeight"
    @before-leave="setHeight"
    @leave="clearHeight"
  >
    <div
      class="overflow-hidden transition-[height] duration-200 ease-in-out"
      v-show="active"
    >
      <slot />
    </div>
  </Transition>
</template>

<style scoped lang="scss"></style>
