<script setup lang="ts">
import { defineAsyncComponent } from "vue";

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  active: {
    type: Boolean,
    default: false,
  },
});

const icon = defineAsyncComponent(() => {
  const name = props.active ? `${props.name} active` : props.name;
  return import(`../../assets/icons/${name}.svg`);
});
</script>

<template>
  <div class="relative h-6 w-6">
    <div v-if="active" class="absolute left-1/2 top-1/2 h-[130%] w-[130%]">
      <div
        class="relative -left-1/2 -top-1/2 h-full w-full rounded-full bg-almost-black"
      ></div>
    </div>
    <component :is="icon" class="relative h-full w-full" />
  </div>
</template>

<style scoped lang="scss"></style>
