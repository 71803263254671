import { POSITION, useToast as toastFunction } from "vue-toastification";
import CaToastBody from "~/components/ui/toast/CaToastBody.vue";
import { ToastID } from "vue-toastification/dist/types/types";
import CaToastIconClose from "~/components/ui/toast/CaToastIconClose.vue";

export function useToast() {
  const toast = toastFunction();

  function dismissAll() {
    toast.clear();
  }

  function dismiss(id: ToastID) {
    toast.dismiss(id);
  }

  function success(title = "", text = "") {
    toast.success({
      component: CaToastBody,
      props: {
        title,
        text,
      },
    });
  }

  function error(title = "", text = "") {
    toast.error({
      component: CaToastBody,
      props: {
        title,
        text,
      },
    });
  }

  function notification(title = "", text = "", onclick = () => {}) {
    toast.info(
      {
        component: CaToastBody,
        props: {
          title,
          text,
        },
      },
      {
        timeout: false,
        draggable: false,
        closeButton: CaToastIconClose,
        onClick(closeToast) {
          return onclick();
        },
      },
    );
  }

  function permanent(text = "") {
    return toast.error(
      {
        component: CaToastBody,
        props: {
          title: "",
          text,
        },
      },
      {
        timeout: false,
        closeOnClick: true,
        closeButton: CaToastIconClose,
        draggable: false,
        position: POSITION.TOP_RIGHT,
      },
    );
  }

  return { dismissAll, dismiss, success, error, notification, permanent };
}
