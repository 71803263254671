import type { UserModule } from "./types/app";
import App from "./App.vue";
import { createApp } from "vue";
import * as Sentry from "@sentry/vue";
import { router, routes } from "./router";
import "./styles/index.scss";

async function setup() {
  const app = createApp(App);

  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_ENV,
    integrations:
      import.meta.env.VITE_ENV === "production"
        ? [
            new Sentry.BrowserTracing({
              tracePropagationTargets: [import.meta.env.VITE_API_URL],
            }),
            new Sentry.Replay(),
          ]
        : [],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  app.use(router);

  // Auto-import from /src/plugins
  Object.values(
    import.meta.glob<{ install: UserModule }>("./plugins/*.ts", {
      eager: true,
    }),
  ).forEach((i) => i.install?.({ app, router, routes }));

  try {
    app.mount("#app");
  } catch (error) {
    console.log(error);
  }
}

setup();
