import { UserModule } from "~/types/app";
import Toast, { PluginOptions, POSITION, TYPE } from "vue-toastification";
import "~/styles/toast.scss";
import CaToastIconClose from "~/components/ui/toast/CaToastIconClose.vue";
import CaToastIconSuccess from "~/components/ui/toast/CaToastIconSuccess.vue";
import CaToastIconError from "~/components/ui/toast/CaToastIconError.vue";
import { ToastComponent } from "vue-toastification/src/types";

const options: PluginOptions = {
  position: POSITION.TOP_RIGHT,
  timeout: 8000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.8,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: CaToastIconClose,
  rtl: false,
  transition: "Vue-Toastification__fade",

  toastDefaults: {
    [TYPE.ERROR]: {
      icon: CaToastIconError,
    },
    [TYPE.SUCCESS]: {
      icon: CaToastIconSuccess,
    },
  },

  filterBeforeCreate: (toast, toasts) => {
    if (
      toasts.filter(
        (t) =>
          t.type === toast.type &&
          (t.content as ToastComponent).props?.text ===
            (toast.content as ToastComponent).props?.text,
      ).length !== 0
    ) {
      // Returning false discards the toast
      return false;
    }
    // You can modify the toast if you want
    return toast;
  },
};

export const install: UserModule = ({ app }) => {
  app.use(Toast, options);
};
